import styled from 'styled-components'

export const SettingContainer = styled.div`
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 400px;
`
