import * as Const from 'common/constants'
import { Match, Participant, Round } from 'typings'
import { TeamFormat } from 'common/constants'
import alfaLavalLogo from 'assets/img/alfa-laval.jpg'
import bjurforsLogo from 'assets/img/bjurfors.png'
import elProduktionLogo from 'assets/img/elektro-produktion.jpg'
import globalInvestLogo from 'assets/img/global-invest.png'
import gullikssonLogo from 'assets/img/gulliksson.jpg'
import mammuthLogo from 'assets/img/mammuth.jpg'
import orestadLogo from 'assets/img/orestadskyl.png'
import stockholmsElbolagLogo from 'assets/img/stockholms-elbolag.png'
import svenskCaterLogo from 'assets/img/svenskcater.png'
import toveksBilLogo from 'assets/img/toveks-bil.png'

const courtNames = [
  'Gulliksson',
  'Mammuth',
  'Örestad Kyl',
  'Svensk Cater',
  'Global Invest',
  'Elektroproduktion',
  'Bjurfors',
]

const testNames = [
  'Benny Boxare',
  'Göran Hägglund',
  'Arne Weise',
  'Donald Trump',
  'Jose Gonzales',
  'Ruben Östlund',
  'Kalle Anka',
  'Diego Maradona',
  'Snurre Sprätt',
  'The Rock',
  'Annie Lööf',
  'Stefan Löfven',
  'Göran Greider',
  'Steffi Graaf',
  'Moder Theresa',
  'Dalai Lama',
  'Salvador Dali',
  'Zlatan',
  'Fedor Emelianenko',
  'Bernie Sanders',
  'Kurt Olsson',
  'Klas Ingelsson',
  'Mikael Root',
]

export const getCssColor = (color: string) => {
  switch (color) {
    case 'orange':
      return '#be8164'
    case 'blue':
      return '#669bc3'
    case 'pickleball':
      return '#3455a2'
    case 'green':
    default:
      return '#3a9030'
  }
}

export const getCourtNames = (count: number) =>
  courtNames.slice(0, Math.min(courtNames.length, count))

export const getTestNames = (count: number) =>
  testNames.slice(0, Math.min(testNames.length, count))

export const getParticipant = (participants: Participant[], id: string) =>
  participants.find((p) => p.id === id)

// This function is only to be used for 11/21/24 format, we don't allow playerCount % 4 !== 0 for
// free point mode.
export const getScoreForPassing = (matchFormat: string) => {
  switch (matchFormat) {
    case Const.FOR_11_POINTS:
      return 6
    case Const.FOR_21_POINTS:
      return 11
    case Const.FOR_24_POINTS:
      return 12
    default:
      return 11
  }
}

/*  eslint-disable complexity */
export const getCourtLogoAndColor = (name: string) => {
  const lcName = name.toLowerCase()

  if (lcName.includes('alfa') && lcName.includes('laval')) {
    return { logo: alfaLavalLogo, color: 'blue' }
  }
  if (lcName.includes('bjurfors')) {
    return { logo: bjurforsLogo, color: 'blue' }
  }
  if (
    (lcName.includes('elektro') || lcName.includes('electro')) &&
    lcName.includes('produktion')
  ) {
    return { logo: elProduktionLogo, color: 'blue' }
  }
  if (lcName.includes('global') && lcName.includes('invest')) {
    return { logo: globalInvestLogo, color: 'blue' }
  }
  if (lcName.includes('sixt')) {
    return { logo: null, color: 'blue' }
  }
  if (lcName.includes('gulliksson')) {
    return { logo: gullikssonLogo, color: 'orange' }
  }
  if (lcName.includes('mammut')) {
    return { logo: mammuthLogo, color: 'orange' }
  }
  if (lcName.includes('örestad') && lcName.includes('kyl')) {
    return { logo: orestadLogo, color: 'orange' }
  }
  if (lcName.includes('svensk') && lcName.includes('cater')) {
    return { logo: svenskCaterLogo, color: 'orange' }
  }
  if (lcName.includes('stockholm') && lcName.includes('bolag')) {
    return { logo: stockholmsElbolagLogo, color: 'blue' }
  }
  if (lcName.includes('tovek')) {
    return { logo: toveksBilLogo, color: 'blue' }
  }

  return { logo: null, color: 'orange' }
}
/* eslint-enable complexity */

// Get random int, both minimum and maximum are inclusive
export const getRandomInt = (minimum: number, maximum: number) => {
  const min = Math.ceil(minimum)
  const max = Math.floor(maximum + 1)
  return Math.floor(Math.random() * (max - min)) + min
}

export const shuffle = <T>(array: T[]): T[] => {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}

export const updatePlayerMatchingRepetitions = (
  matches: Match[],
  players: Participant[],
  teamFormat: string
) => {
  // Update who has played with and against who
  const updatePlayedCounters = (
    player: Participant,
    teamMateId: string | null,
    opponents: Participant[]
  ) => {
    opponents
      .map((o) => o.id)
      .forEach((opponentId) => {
        if (player.playedAgainstCounters[opponentId]) {
          player.playedAgainstCounters[opponentId] += 1 // eslint-disable-line no-param-reassign
        } else {
          player.playedAgainstCounters[opponentId] = 1 // eslint-disable-line no-param-reassign
        }
      })
    if (teamMateId) {
      if (player.playedWithCounters[teamMateId]) {
        player.playedWithCounters[teamMateId] += 1 // eslint-disable-line no-param-reassign
      } else {
        player.playedWithCounters[teamMateId] = 1 // eslint-disable-line no-param-reassign
      }
    }
  }
  matches.forEach((match) => {
    const p1 = players.find((p) => p.id === match.teams[0].players[0].id)
    const p3 = players.find((p) => p.id === match.teams[1].players[0].id)

    if (!p1 || !p3) {
      throw new Error('Player not found')
    }

    // player teamMateId, opponents

    if (teamFormat === TeamFormat.Individual) {
      const p2 = players.find((p) => p.id === match.teams[0].players[1].id)
      const p4 = players.find((p) => p.id === match.teams[1].players[1].id)

      if (!p2 || !p4) {
        throw new Error('Player not found')
      }

      updatePlayedCounters(p1, p2.id, match.teams[1].players)
      updatePlayedCounters(p3, p4.id, match.teams[0].players)
      updatePlayedCounters(p2, p1.id, match.teams[1].players)
      updatePlayedCounters(p4, p3.id, match.teams[0].players)
    } else {
      updatePlayedCounters(p1, null, match.teams[1].players)
      updatePlayedCounters(p3, null, match.teams[0].players)
    }
  })

  return players
}

export const getAverage = (arrayOfArrays: number[][]) => {
  const val =
    arrayOfArrays.reduce(
      (a, v) => a + v.reduce((a2, v2) => a2 + v2, 0) / v.length,
      0
    ) / arrayOfArrays.length

  return Number.isNaN(val) ? 0 : val
}

export const getMax = (arrayOfArrays: number[][]) => {
  const val = arrayOfArrays.reduce(
    (a, v) =>
      Math.max(
        a,
        v.reduce((a2, v2) => Math.max(a2, v2), 0)
      ),
    0
  )

  return Number.isNaN(val) ? 0 : val
}

export const evaluateRound = (round: Round) => {
  const players: Participant[] = []

  round.matches.forEach((match) => {
    match.teams.forEach((team) => {
      team.players.forEach((player) => {
        players.push(player)
      })
    })
  })

  const playedWith = Object.values(players).map((v) =>
    Object.values(v.playedWithCounters)
  )
  const playedAgainst = Object.values(players).map((v) =>
    Object.values(v.playedAgainstCounters)
  )

  const stats = {
    against: {
      avg: getAverage(playedAgainst),
      max: getMax(playedAgainst),
    },
    with: {
      avg: getAverage(playedWith),
      max: getMax(playedWith),
    },
  }

  return {
    ...stats,
    score:
      stats.against.avg + stats.against.max + stats.with.avg + stats.with.max,
  }
}

export const calculateMatchButtonSize = ({
  nbrButtons,
  windowHeight,
  windowWidth,
}: {
  nbrButtons: number
  windowHeight: number
  windowWidth: number
}) => {
  const MaxButtonSize = 140
  const buttonArea = (windowHeight * windowWidth) / nbrButtons

  const buttonSize = Math.min(MaxButtonSize, Math.sqrt(buttonArea))

  return buttonSize - 50
}

export const isProd = import.meta.env.PROD

export const InSweden =
  Intl.DateTimeFormat().resolvedOptions().timeZone === 'Europe/Stockholm'
