import { Integration, PhaseInfo, Round } from 'typings'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { AppDispatch } from 'store'
import CommandRow from 'components/commandrow/CommandRow'
import DebugPanel from 'components/debugpanel'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'
import QueryParamExtractor from './query-param-extractor'
import ReactGA from 'react-ga'
import ResumeTournamentDialog from 'components/settings/resumeTournamentDialog'
import RoundComponent from 'components/round'
import Settings from 'components/settings'
import Spinner from 'components/spinner'
import Summary from 'components/summary'
import { actionCreators } from 'actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loadState } from 'localstorage'
import messagesDa from 'translations/da.json'
import messagesEn from 'translations/en.json'
import messagesEs from 'translations/es.json'
import messagesNo from 'translations/no.json'
import messagesPl from 'translations/pl.json'
import messagesPt from 'translations/pt.json'
import messagesSv from 'translations/sv.json'

const messages = {
  da: messagesDa,
  en: messagesEn,
  es: messagesEs,
  no: messagesNo,
  pl: messagesPl,
  pt: messagesPt,
  sv: messagesSv,
}

interface Props {
  currentPhase: PhaseInfo
  integration: Integration
  language: string
  newTournament: () => void
  spinnerVisible: boolean
}

const getPhasePage = (currentPhase: PhaseInfo) => {
  switch (currentPhase.phase) {
    case 'settings':
      return <Settings />
    case 'round':
      return <RoundComponent roundNbr={currentPhase.index!} />
    case 'summary':
      return <Summary />
    default:
      return <div>Unexpected phase: {currentPhase.phase}</div>
  }
}

const App = ({
  currentPhase,
  integration,
  language,
  newTournament,
  spinnerVisible,
}: Props) => {
  const [showResumeDialog, setShowResumeDialog] = useState(false)

  useEffect(() => {
    if (window.location.hostname != 'localhost') {
      ReactGA.initialize('UA-131516570-1')
      ReactGA.pageview('/')
    }
    console.log('client version: ', import.meta.env.VITE_GIT_SHA)
  }, [])

  useEffect(() => {
    const storedState = loadState()
    if (storedState?.currentPhase.phase !== 'settings') {
      setShowResumeDialog(true)
    }
  }, [])

  if (window.location.search) {
    return <QueryParamExtractor />
  }

  const phasePage = getPhasePage(currentPhase)

  const spinner = spinnerVisible ? <Spinner /> : null

  const theme = createTheme({
    typography: {
      fontFamily: 'Roboto',
      fontSize: 14,
    },
  })

  const bgStyle =
    integration?.vendor === 'padellink'
      ? {
          backgroundImage: `url('/padellink.jpg')`,
          backgroundSize: '1430px 287',
          opacity: 0.08,
        }
      : {
          backgroundImage: `url('/logo.png')`,
          backgroundSize: '400px 400px',
          opacity: 0.025,
        }

  return (
    /* @ts-expect-error react-intl not exporting the types needed */
    <IntlProvider locale={language} messages={messages[language]}>
      <ThemeProvider theme={theme}>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              ...bgStyle,
              position: 'fixed',
              top: '50%',
              left: '50%',
              width: '200%',
              height: '200%',
              backgroundRepeat: 'repeat',
              transform: 'translate(-50%, -50%) rotate(20deg)',
              zIndex: -1,
              overflow: 'hidden',
            }}
          />
          <div
            style={{
              fontFamily: 'Roboto, sans-serif',
              position: 'relative',
              zIndex: 1,
            }}
          >
            {spinner}
            {showResumeDialog && (
              <ResumeTournamentDialog
                onClose={() => {
                  newTournament()
                  setShowResumeDialog(false)
                }}
                onConfirm={() => {
                  setShowResumeDialog(false)
                }}
              />
            )}
            {!showResumeDialog && phasePage}
            <DebugPanel />
            {!showResumeDialog && <CommandRow />}
          </div>
        </div>
      </ThemeProvider>
    </IntlProvider>
  )
}

const mapStateToProps = (state: {
  currentPhase: PhaseInfo
  language: string
  integration: Integration
  rounds: Round[]
  spinnerVisible: boolean
}) => ({
  currentPhase: state.currentPhase,
  integration: state.integration,
  language: state.language,
  rounds: state.rounds,
  spinnerVisible: state.spinnerVisible,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(actionCreators, dispatch)

App.propTypes = {
  currentPhase: PropTypes.object.isRequired,
  integration: PropTypes.object,
  language: PropTypes.string.isRequired,
  newTournament: PropTypes.func.isRequired,
  rounds: PropTypes.array.isRequired,
  spinnerVisible: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
