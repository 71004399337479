import * as Const from 'common/constants'
import { CenterStyle, LeftStyle, RightStyle } from './CommandRowStyles'
import { Court, Participant, Round } from 'typings'
import AboutButton from './AboutButton'
import { AppDispatch } from 'store'
import Button from '@material-ui/core/Button'
import Dialog from 'components/dialog'
import PropTypes from 'prop-types'
import { TeamFormat } from 'common/constants'
import { actionCreators } from 'actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getLanguages } from './languages'
import { terms } from 'common/terms'
import { useIntl } from 'react-intl'
import { useState } from 'react'

interface Props {
  courts: Court[]
  generateRound: () => void
  language: string
  participants: Participant[]
  matchFormat: string
  setLanguage: (_: string) => void
  startTournament: () => void
  teamFormat: TeamFormat
  width: number
}

const SettingsCommandRow = ({
  courts,
  generateRound,
  language,
  matchFormat,
  participants,
  setLanguage,
  startTournament,
  teamFormat,
  width,
}: Props) => {
  const { formatMessage: fm } = useIntl()
  const [confirmationOpen, setConfirmationOpen] = useState(false)

  const getDialogContent = () => {
    if (teamFormat === Const.TeamFormat.Individual) {
      if (participants.length > courts.length * 4) {
        return `${fm(terms.nbrRestingPlayers)}: ${
          participants.length - courts.length * 4
        }`
      }
      return `${fm(terms.nbrRestingPlayers)}: ${participants.length % 4}`
    } else {
      if (participants.length > courts.length * 2) {
        return `${fm(terms.nbrRestingTeams)}: ${
          participants.length - courts.length * 2
        }`
      }
      return `${fm(terms.nbrRestingTeams)}: ${participants.length % 2}`
    }
  }

  let createDisabled
  switch (matchFormat) {
    case Const.FOR_11_POINTS:
    case Const.FOR_21_POINTS:
    case Const.FOR_24_POINTS:
      createDisabled = !(
        courts.length >= Const.MIN_NBR_OF_COURTS &&
        participants.length >= Const.MIN_NBR_OF_PARTICIPANTS
      )
      break
    default: {
      // Free point mode also requires participants.length % participantsPerMatch === 0
      // what score would passing player get otherwise?
      const participantsPerMatch =
        teamFormat === Const.TeamFormat.Individual ? 4 : 2
      createDisabled = !(
        courts.length >= Const.MIN_NBR_OF_COURTS &&
        participants.length >= Const.MIN_NBR_OF_PARTICIPANTS &&
        participants.length % participantsPerMatch === 0
      )
    }
  }
  let centerComponent =
    width > 800 ? (
      <div style={{ fontSize: '12pt' }}>
        {' '}
        <span>2025-03-03: Set language via query param</span>
      </div>
    ) : null

  if (!createDisabled) {
    centerComponent = (
      <Button
        data-test-id="start-tournament"
        variant="contained"
        color="primary"
        onClick={() => {
          if (
            (teamFormat === Const.TeamFormat.Individual &&
              (participants.length % 4 !== 0 ||
                participants.length > courts.length * 4)) ||
            (teamFormat !== Const.TeamFormat.Individual &&
              (participants.length % 2 !== 0 ||
                participants.length > courts.length * 2))
          ) {
            setConfirmationOpen(true)
          } else {
            startTournament()
            generateRound()
          }
        }}
        style={{ fontSize: '10px' }}
      >
        {fm(terms.startTournament)}
      </Button>
    )
  }

  return (
    <>
      <Dialog
        confirmTestId="confirm-resting"
        content={getDialogContent()}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={() => {
          setConfirmationOpen(false)
          startTournament()
          generateRound()
        }}
        open={confirmationOpen}
      />
      <div style={LeftStyle}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            marginLeft: '10px',
            width: '200px',
          }}
        >
          {getLanguages(language).map((lang) => (
            <div
              key={lang.code}
              style={{
                margin: '2px',
                opacity: lang.selected ? 1.0 : 0.3,
              }}
            >
              <img
                draggable="false"
                onClick={() => setLanguage(lang.code)}
                style={{ height: '30px' }}
                src={lang.src}
                alt={lang.name}
              />
            </div>
          ))}
        </div>
      </div>
      <div style={CenterStyle}>{centerComponent}</div>
      <div style={RightStyle}>
        <AboutButton />
      </div>
    </>
  )
}

const mapStateToProps = (
  state: {
    courts: Court[]
    language: string
    matchFormat: string
    participants: Participant[]
    rounds: Round[]
    teamFormat: TeamFormat
  },
  ownProps: { width: number }
) => ({
  courts: state.courts,
  language: state.language,
  matchFormat: state.matchFormat,
  participants: state.participants,
  rounds: state.rounds,
  teamFormat: state.teamFormat,
  width: ownProps.width,
})

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(actionCreators, dispatch)

SettingsCommandRow.propTypes = {
  courts: PropTypes.array.isRequired,
  currentPhase: PropTypes.object,
  generateRound: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  matchFormat: PropTypes.string.isRequired,
  participants: PropTypes.array.isRequired,
  rounds: PropTypes.array,
  setLanguage: PropTypes.func.isRequired,
  startTournament: PropTypes.func.isRequired,
  teamFormat: PropTypes.string.isRequired,
  transitionToPreviousOrLastRound: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCommandRow)
